import { graphql, StaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container } from "react-bootstrap"
import Benefit from "../components/common/Benefit-box/Benefit"
import Block from "../components/common/Block/Block"
import CTA from "../components/common/CTA"
import FooterLP from "../components/common/footer-lp"
import Frame from "../components/common/frame"
import Hero from "../components/common/Hero/Hero"
import ImagePointer from "../components/common/ImagePointer/ImagePointer"
import PopupSignup from "../components/common/popup-sign-up"
import SVGIcon from "../components/common/SVGIcon"
import TabToggler from "../components/common/Tab-toggler/TabToggler"
import Testimonial from "../components/common/testimonial-component/testimonial"
import VerticalTabs from "../components/common/VerticalTabComponent/VerticalTabs"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import Slide from "../components/utility/slide_content"
import "../styles/feature-details-new.scss"
// import "../styles/features.scss"

function FeatureDetailsNew({ pageContext }) {
  const { feature } = pageContext

  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      required: false,
      errorMessage: "Please enter company",
    },
    {
      name: "phone",
      type: "phone",
      label: "Mobile number",
      required: false,
      errorMessage: "Please enter a mobile number",
    },
  ]

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentFeatureDetailsNew {
            SuperOps {
              pages(where: { title: "Feature Details" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
              links(where: { section: "Footer Security" }) {
                linkName
                slug
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { navigationBlock } = page

              return (
                <div className={`feature-details-new ${feature.hideFooterNavTabs ===  "Yes" ? "hide-nav-tabs" : ""}`}>
                  <Frame
                    seo={feature.seos[0]}
                    newButton
                    IsFooterVisble={feature.hideFooterNavTabs ===  "Yes" && "No"}
                  >
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page="About us"
                        newButton
                        primaryButtonText={feature.hideFooterNavTabs ===  "Yes" && "GET STARTED FOR FREE"}
                        signupPopup={feature.hideFooterNavTabs ===  "Yes" && true}
                        disallowCommonDomains={feature.hideFooterNavTabs ===  "Yes" && true}
                      />
                    </header>

                    <div>
                      <PopupSignup
                        visibility={popupVisibility}
                        togglePopup={togglePopup}
                        disallowCommonDomains
                      />
                    </div>

                    <section className="section-wrapperr">
                      {feature.components.map((field, ind) => {
                        switch (field.__typename) {
                          case "SuperOps_Hero": {
                            return (
                              <>
                                <Hero
                                  backgroundImage={field.backgroundColor}
                                  tag={field.tag}
                                  heading={field.heading.text}
                                  description={field.heroExcerpt}
                                  primaryButtonText={field.primaryButtonText}
                                  primaryButtonLink={field.primaryButtonLink}
                                  secondaryButtonText={
                                    field.secondaryButtonText
                                  }
                                  secondaryButtonLink={
                                    field.secondaryButtonLink
                                  }
                                  image={field.heroImage.url}
                                  imageMob={field.heroImageMob?.url}
                                  openPopUp={() => togglePopup()}
                                  newButton
                                  popupForm={feature.hideFooterNavTabs ===  "Yes" && true}
                                />
                              </>
                            )
                          }
                          case "SuperOps_Benefit": {
                            return (
                              <Benefit
                                heading={field.benefitsHeading}
                                icon={field.benefitsIcon}
                                description={field.benefitsExcerpt}
                              />
                            )
                          }
                          case "SuperOps_Card": {
                            if (field.cardType === "Double_cards") {
                              return (
                                <div className="double-card altv3">
                                  <Container className="Layout-container">
                                    <h1 className="font-roboto fw-bold main-heading mx-auto text-center mb50">
                                      {field.cardName}
                                    </h1>
                                    <div className="d-flex justify-content-between flex-wrap">
                                      {field.text.map((item, ind) => {
                                        return (
                                          <div className="parent-box mb30">
                                            <div className="mb30">
                                              <img
                                                src={field.image[ind].url}
                                                className="w-100"
                                                alt="image"
                                              />
                                            </div>
                                            <p className="p24 font-roboto fw-bold mb8">
                                              {item}
                                            </p>
                                            <p className="p16 description m-0">
                                              {field.subtext[ind]}
                                            </p>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </Container>
                                </div>
                              )
                            } else if (field.cardType === "Plain_cards") {
                              return (
                                <>
                                  <div
                                    className="plain-image"
                                    style={{
                                      background: `${field.cardDescription}`,
                                    }}
                                  >
                                    <div className="dot-bg w-100 h-100"></div>
                                    <img
                                      className="w-100 image prelative"
                                      src={
                                        field.image[screenWidth >= 992 ? 0 : 2]
                                          .url
                                      }
                                      alt="image"
                                    />
                                  </div>
                                  {field.image[1]?.url && (
                                    <div
                                      className={`mx-auto text-center mt24 ${
                                        screenWidth >= 992 ? "w-50" : "w-75"
                                      }`}
                                    >
                                      <img
                                        className="w-100"
                                        src={field.image[1]?.url}
                                        alt="image"
                                      />
                                    </div>
                                  )}
                                </>
                              )
                            } else {
                              return <ImagePointer data={field} version="version-v1" trigger="manual"/>
                            }
                          }
                          case "SuperOps_Block": {
                            return (
                              <div
                                className={`${ind === 2 ? "blk-wrapper" : ""}`}
                              >
                                <div className="dot-bg mx-auto">
                                  <Slide delay="200">
                                    <Col lg={6} className="mx-auto">
                                      <h2 className="blk-heading text-center font-roboto fw-bold mb50">
                                        {field.linkName}
                                      </h2>
                                    </Col>
                                  </Slide>
                                  <div
                                    className={`${
                                      field.blockTextPosition === "Right"
                                        ? "text-right"
                                        : "text-left"
                                    }`}
                                  >
                                    <Block
                                      tag={field.blockTag}
                                      heading={field.blockHeading}
                                      description={field.blockDescription}
                                      imageTitle={[""]}
                                      image={field.blockImage}
                                      isImage={["Yes"]}
                                      isVideo={["no"]}
                                      lgLeft={5}
                                      lgRight={7}
                                      decoration={
                                        field.blockIsIllustration[0] === "Yes"
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          }
                          case "SuperOps_Testimonial": {
                            return field.testimonialType === "single_5" ? (
                              <Testimonial
                                type="single_5"
                                testimonial={field}
                              />
                            ) : (
                              <div className="test-without-img">
                                <Container className="Layout-container">
                                  <Col lg={10} className="mx-auto">
                                    <Col lg={9}>
                                      <h2 className="font-roboto fw-bold mb48">
                                        {field.testimonialTitle}
                                      </h2>
                                    </Col>
                                  </Col>
                                  <Col
                                    lg={10}
                                    className="d-flex br10 outer-box mx-auto"
                                  >
                                    <Slide delay="200">
                                      <SVGIcon
                                        name="badge-testimonial/quotesPurple"
                                        className="mb38 quote-svg position-relative"
                                      />
                                    </Slide>
                                    <Slide delay="200">
                                      <p className="content font-roboto fw-bold p20 mb30">
                                        {field.testimonialDatas[0].content}
                                      </p>
                                      <h4 className="font-roboto fw-bold mb16">
                                        {field.testimonialDatas[0].name}
                                      </h4>
                                      <p className="p14 company m-0">
                                        {field.testimonialDatas[0].title}
                                      </p>
                                    </Slide>
                                  </Col>
                                </Container>
                              </div>
                            )
                          }
                          case "SuperOps_FaqComponent": {
                            return <VerticalTabs data={field} />
                          }
                          case "SuperOps_Advantage": {
                            return (
                              <TabToggler
                                index={ind}
                                heading={field.advantagesHeading[0]}
                                description={field.advantagesDescription[0]}
                                bgColor={field.bgColor}
                                pattern={field.illustrationPattern}
                                title={field.advantagesExcerpt}
                                images={field.advantagesIcon}
                              />
                            )
                          }
                          case "SuperOps_CtaFeature": {
                            return (
                              <section className="feature-CTA">
                                <Slide delay="200">
                                  <CTA
                                    data={[field.ctaBox]}
                                    className="Layout-container"
                                    lgLeft={6}
                                    lgRight={4}
                                    rightStyles={{ alignItems: "center" }}
                                    newButton
                                    newDesign
                                    openPopUp={feature.hideFooterNavTabs !==  "Yes" ? () => togglePopup() : null}
                                    disallowCommonDomains
                                  />
                                </Slide>
                              </section>
                            )
                          }
                        }
                      })}
                    </section>
                    {feature.hideFooterNavTabs ===  "Yes" && <FooterLP items={data.SuperOps.links[0]} />}
                    <CTAThankYou />
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default FeatureDetailsNew
